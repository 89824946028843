import * as React from 'react';
import { guid } from '@inwink/utils/methods/guid';
import { IDynamicBlocBaseProps, DynamicBloc } from '../../../components/dynamicpage/common';
import { BlocTitle } from "../../../components/dynamicpage/common.title";
import { PictureGalleryBase, IBlocPictureGalleryProps, getEntityItems, getNestedEntityItems } from './bloc.picturegallery.base';
import { registerBloc } from '../../../components/dynamicpage/blocscatalog';

export class BlocPictureGallery extends React.PureComponent<IDynamicBlocBaseProps, any> {
    constructor(props: IDynamicBlocBaseProps) {
        super(props);
        this.state = {
            blocProps: getProps(props.template.id || guid(), props)
        };
    }

    render() {
        const props = this.state.blocProps;
        if (!props || !props.items || !props.items.length) {
            return <div className="empty" />;
        }

        return <DynamicBloc {...this.props} className={"picturegallery " + (this.props.className || "")}>
            <BlocTitle {...this.props} className="bloc-header" />
            <PictureGalleryBase {...props} />
        </DynamicBloc>;
    }
}

function getProps(id: string, props: IDynamicBlocBaseProps) {
    const templateProperties = props.template && props.template.properties;
    const resprops: IBlocPictureGalleryProps = {
        ...props,
        display: "mozzaik",
        contentClassName: "bloc-content",
        sliderSettings: templateProperties && templateProperties.sliderSettings,
        themeId: props.event && props.event.eventid ? "event-" + props.event.eventid : "",
        aspectRatio: templateProperties && templateProperties.ratio,
        id: id,
        itemColumns: templateProperties && templateProperties.itemColumns,
        itemMargin: templateProperties && templateProperties.itemMargin,
        itemWidth: templateProperties && templateProperties.itemWidth,
        maxItems: templateProperties && templateProperties.maxItems,
        showPager: templateProperties && templateProperties.showPager,
        disableFullScreenView: templateProperties && templateProperties.disableFullScreenView,
        items: (templateProperties && templateProperties.pictures) || (templateProperties && templateProperties.videos) || []
    };

    if (templateProperties && templateProperties.display === "carousel") {
        resprops.display = "carousel";
    }
    if (templateProperties && templateProperties.display === "thumbcarousel") {
        resprops.display = "thumbcarousel";
    }
    if (templateProperties && templateProperties.display === "carouselwiththumb") {
        resprops.display = "carouselwiththumb";
    }

    if (templateProperties && templateProperties.display === "default") {
        resprops.display = "default";
    }

    if (templateProperties && templateProperties.entityFields && props.datacontext.entity) {
        resprops.items = getEntityItems(templateProperties.entityFields, props.datacontext.entity);
    }

    if (templateProperties && templateProperties.fromNestedProperty && props.datacontext.entity) {
        resprops.items = getNestedEntityItems(templateProperties.fromNestedProperty, props.datacontext.entity);
    }

    return resprops;
}

registerBloc("picturegallery", {
    component: BlocPictureGallery,
    datainit: () => { }
});

registerBloc("videogallery", {
    component: BlocPictureGallery,
    datainit: () => { }
});
